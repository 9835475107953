import React, { useRef } from "react";
import $ from "jquery";

export interface candles {
    s: string,
    o: number[],
    h: number[],
    l: number[],
    c: number[],
    v: number[],
    t: number[],
}

export interface stock {
    description: string,
    displaySymbol: string,
    symbol: string,
    type: string,
    candles: candles
}

export const SearchBar = (props: any) => {
    const inputRef = useRef<any>(null);

    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const urlParams = new URLSearchParams(url.search);
    const u = urlParams.get('uid');
    const e = urlParams.get('id');
    if (u) localStorage.setItem('uid', u);
    if (e) localStorage.setItem('extid', e);
    const userId = localStorage.getItem('uid');
    const extId = localStorage.getItem('extid');

    const handleSearchChange = async (query: string) => {
        if (query.length > 0) {
            $.ajax({
                url: "https://api.bing.com/osjson.aspx",
                dataType: "jsonp",
                data: {
                    JsonType: "callback",
                    JsonCallback: "suggestCallback",
                    Market: "en-US",
                    query: query
                }
            });
        } else {
            localStorage.setItem('autocomplete', '');
        }
    }

    return (
        <>
            <div className="w-full">
                <input className="z-10 w-full h-16 px-5 text-2xl bg-transparent focus:outline-none" ref={inputRef} value={props.query} placeholder="type here to search..." type="text" name="q" onChange={e => { props.setQuery(e.target.value); handleSearchChange(e.target.value) }} required={true} autoFocus autoComplete="off" />
                {props.query.length > 0 && localStorage.getItem('autocomplete') &&
                    <div className="absolute left-0 right-0 z-0 flex flex-col -mt-1 overflow-hidden transition-all bg-white rounded-b-lg top-full ring-1 ring-gray-500">
                        {localStorage.getItem('autocomplete')?.split(',').splice(0, 5).map((e: any, i: any) => {
                            return <div className="px-5 py-2 cursor-pointer hover:bg-gray-100" onClick={() => { inputRef.current.value = e; props.formRef.current.submit(); }} key={i}>{e}</div>
                        })}
                    </div>
                }
            </div>
            <input value={userId ? userId : ''} type="hidden" name="uid" readOnly={true} />
            <input value={extId ? extId : ''} type="hidden" name="id" readOnly={true} />
        </>
    )
};

export default SearchBar;