import React, { useRef, useState, useEffect, useCallback } from "react";
import SearchBar from "./components/SearchBar"
import { saveAs } from 'file-saver'
// export interface data {
//     meaning: string[]
// }

export const App = () => {
    const domain = "qrcodeme.xyz"
    const [query, setQuery] = useState("");
    const divRef = useRef<any>(null);
    const formRef = useRef<any>(null);
    const [data, setData] = useState<string | undefined>();
    const [host, setHost] = useState<string | undefined>();
    const [hasError, setHasError] = useState(false);

    const generateQR = useCallback((q) => {
        if (q) {
            try {
                let url = q
                if (!/^(?:f|ht)tps?:\/\//.test(url) && url.includes('.')) {
                    url = `https://${url}`
                }
                const parsedURL = new URL(url)
                setQuery(parsedURL.href)
                setHost(parsedURL.host)
                setData(`https://qrtag.net/api/qr_transparent_6.png?url=${encodeURIComponent(parsedURL.href)}`)
                setHasError(false)
            } catch (e) {
                setData(q)
                setHasError(true)
            }
        }
    }, [])

    useEffect(() => {
        const currentURL = window.location.href;
        const url = new URL(currentURL);
        const urlParams = new URLSearchParams(url.search);
        const q = urlParams.get('url');
        if(q) {
            const parsed = new URL(q);
            if(parsed.protocol !== 'chrome:') {
                generateQR(q)
            }
        }
    }, [generateQR])

    const handleQueryChange = (query: any) => {
        setData("")
        setHost("")
        setQuery(query)
    }

    return (
        <div className="w-screen h-screen bg-opacity-50 bg-center bg-no-repeat bg-cover bg-main">
            <div ref={divRef} className={`w-screen h-screen flex flex-col items-center justify-start z-50`} onClick={() => { document.querySelector('input')?.focus(); localStorage.setItem('autocomplete', ''); }}>
                <div className="w-full max-w-2xl p-3 mt-4 bg-white rounded-lg shadow-2xl">
                    <div className={`text-base font-light text-center text-gray-900 ${data ? 'border-b pb-2 mb-2' : ''}`}>Write down a domain URL and click on <span className="font-bold">&ldquo;Generate&rdquo;</span> to get a QR code!</div>
                    {data &&
                        <div className="flex items-center justify-center p-5">
                            {hasError ?
                                <div className="font-light text-center text-gray-800"><span className="font-bold">{data}</span> is not a valid domain</div>
                                :
                                <div className="flex flex-col items-center justify-center">
                                    <p className="w-full max-w-lg px-4 mb-1 overflow-hidden text-sm font-light text-center text-gray-600 overflow-ellipsis whitespace-nowrap">{query}</p>
                                    <img className="w-48 h-48" src={data} alt={query} />
                                    <button className="w-48 p-1 mt-2 font-bold tracking-widest text-white uppercase bg-black" onClick={() => { saveAs(`https://images.weserv.nl/?url=${encodeURIComponent(data)}`, `${host}.png`) }}>
                                        Download
                                    </button>
                                </div>
                            }
                        </div>
                    }
                </div>
                <form ref={formRef} className="absolute z-10 w-full px-5 transform -translate-y-1/2 top-1/2" action={`https://${domain}/s`} method="GET">
                    <div className="relative flex flex-row w-full max-w-5xl mx-auto transition-all bg-white rounded-lg hover:shadow-2xl focus-within:shadow-2xl ring-1 ring-gray-500 focus-within:ring-gray-700">
                        <SearchBar formRef={formRef} query={query} setQuery={handleQueryChange} />
                        <button className="flex flex-col items-center justify-center w-24 p-2 transition-all rounded-lg hover:bg-gray-100 active:outline-none focus:outline-none" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <button className="flex flex-col items-center justify-center w-24 p-2 transition-all rounded-lg hover:bg-gray-100 active:outline-none focus:outline-none" type="button" onClick={() => generateQR(query)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
                            </svg>
                            Generate
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
};
